import React, { Component } from "react"
import "../../myStyle.css"
import "./IndexApp.css"
import { Link } from 'gatsby'
import CallToAction from "../style-compoment/CallToAction"
import TextStyle from "../style-compoment/TextStyle"
import Check from "../../images/index-images/check.png"
// import myVideo from "../../images/index-images/myVideo.mp4"
import { StaticImage } from "gatsby-plugin-image"



const appArray = [
    <StaticImage
        className="IndexApp-image"
        src="../../images/index-images/imageApp/app1.jpg"
        alt="application Kodness" 
    />,
    <StaticImage
        className="IndexApp-image"
        src="../../images/index-images/imageApp/app2.jpg"
        alt="application Kodness" 
    />,
    <StaticImage
        className="IndexApp-image"
        src="../../images/index-images/imageApp/app3.jpg"
        alt="application Kodness" 
    />,
    <StaticImage
        className="IndexApp-image"
        src="../../images/index-images/imageApp/app4.jpg"
        alt="application Kodness" 
    />,
    <StaticImage
        className="IndexApp-image"
        src="../../images/index-images/imageApp/app5.jpg"
        alt="application Kodness" 
    />
]

class IndexApp extends Component {


    state = {
        pos: 0
    }

    scrollApp = () => {


        clearTimeout()



        let newPos = this.state.pos*(document.getElementById("id-indexApp").offsetHeight-10)

        document.getElementById("id-indexApp").scrollTo(0, newPos)

        this.timerHandle = setTimeout(() => {



            let nextPos = this.state.pos + 1

            if (nextPos >= 5) {
                nextPos = 0
            }

            this.setState({
                pos: nextPos
            })

            this.scrollApp()

        }, 2000)

    }

    componentDidMount() {
        this.scrollApp()
    }
    componentWillUnmount() {
        clearTimeout(this.timerHandle)
    }

    render () {




        return (    
            <div className="indexApp-container">
                    <div id="id-indexApp" className="indexApp-image-container">
                        {/* <video className="indexApp-image" autoPlay muted loop src={myVideo}></video> */}
                        {appArray}
                    </div>
                    <div className="indexApp-text-container"> 
                        <div className="indexApp-text-second">
                                <TextStyle
                                    id="i-41"
                                    class="title big indexApp-title animate"
                                    french={["Augmentez vos résultats avec notre ", <span className="orange">coach numérique</span>]}
                                    english="A complete app"
                                    spanish="Una aplicación completa"
                                    cssStyle={{color: "white"}}
                                ></TextStyle>
                                <div id="i-42" className="indexApp-text-third animate">
                                    <div className="indexApp-check-container">
                                        <img className="indexApp-check" src={Check} alt="check"></img>
                                        <TextStyle
                                            class="medium text"
                                            french={["Progressez à votre rythme, ", <span className="bold"> de chez vous, </span>, <br className="indexApp-br"></br>," avec nos programmes et tutoriels"]}
                                            english="Progress"
                                            spanish="Progreso"
                                            cssStyle={{color: "white"}}
                                        ></TextStyle>
                                    </div>
                                    <div className="indexApp-check-container">
                                        <img className="indexApp-check" src={Check} alt="check"></img>
                                        <TextStyle
                                            class="medium text"
                                            french={["Évaluez l’ensemble de ", <span className=" bold"> vos métriques</span>]}
                                            english="Track your progress"
                                            spanish="Haz un seguimiento de tu progreso"
                                            cssStyle={{color: "white"}}
                                        ></TextStyle>
                                    </div>
        
                                </div>
                                <div className="indexApp-download-container">
                                    <StaticImage
                                        className="indexApp-download"
                                        src="../../images/general-icon/google.png"
                                        alt="HomeView-app" 
                                    />
                                    <StaticImage
                                        className="indexApp-download"
                                        src="../../images/general-icon/apple.png"
                                        alt="HomeView-app" 
                                    />
                                </div>
                        
                        </div>               
                    </div>
            </div>
        )

    }
}

export default IndexApp
