import React, { Component } from "react"
import "./IndexDelivery.css"
import noInstallation from "../../images/index-images/noInstallation.png"
import silence from "../../images/index-images/silence.png"
import solid from "../../images/index-images/solid.png"
import suitcase from "../../images/index-images/suitcase.png"
import TextStyle from "../style-compoment/TextStyle"

class IndexDelivery extends Component {

    render () {

        return (    
            <div className="indexDelivery-container">
                <div className="indexDelivery-column">
                    <div className="indexDelivery-second">
                        <img className="indexDelivery-icon" src={noInstallation} alt=""></img>
                        <TextStyle
                            id="i-61"
                            class="medium bold indexDelivery-text animate" 
                            french={["Installation", <br></br>,"en 10 minutes"]}
                            english="Shipping within 3 days"
                            spanish="Envío en 3 días</span>"
                        ></TextStyle>
                    </div>
                    <div className="indexDelivery-second">
                        <img className="indexDelivery-icon" src={silence} alt=""></img>
                        <TextStyle
                            id="i-62"
                            class="medium bold indexDelivery-text animate"
                            french="Très faible bruit"
                            english="Payment in 3x free of charge"
                            spanish="Pago en 3x sin cargo"
                        ></TextStyle>
                    </div>
                </div>
                <div className="indexDelivery-column">
                    <div className="indexDelivery-second">
                        <img className="indexDelivery-icon" src={solid} alt=""></img>
                        <TextStyle
                            id="i-63"
                            class="medium bold indexDelivery-text animate"
                            french={["Durable", <br></br>,"et robuste"]}
                            english="Easy to install"
                            spanish="Fácil de instalar"
                        ></TextStyle>
                    </div>
                    <div className="indexDelivery-second">
                        <img className="indexDelivery-icon" src={suitcase} alt=""></img>
                        <TextStyle
                            id="i-64"
                            class="medium bold indexDelivery-text animate"
                            french="Transportable"
                            english="Easy to install"
                            spanish="Fácil de instalar"
                        ></TextStyle>
                    </div>
                </div>
            </div>
        )

    }
}

export default IndexDelivery
