import React, { Component } from "react"
import "./IndexValues.css"
import TextStyle from "../style-compoment/TextStyle"
import CallToAction from "../style-compoment/CallToAction"
import { Link } from 'gatsby'

import madeInFrance from "../../images/general-icon/madeInFrance.png"

class IndexValues extends Component {

    render () {
        
        return (
            <div className="IndexValues-container">
                <img className="IndexValues-icon" src={madeInFrance} alt="made in France"></img>
                <div className="IndexValues-text-container">
                    <TextStyle
                        id="i-81"
                        class="title big IndexValues-title animate"
                        french={["Appareil conçu et fabriqué ", <span className="orange">en France</span>]}
                        english="What customers say about us" 
                        spanish="Lo que dicen los clientes sobre nosotros"
                    ></TextStyle>
                    <TextStyle
                        id="i-82"
                        class="medium IndexValues-text animate"
                        french={["Afin de garantir une qualité optimale et respecter nos valeurs, nous avons fait le choix de ", <span className="bold">fabriquer en France.</span>]}
                        english="What customers say about us" 
                        spanish="Lo que dicen los clientes sobre nosotros"
                    ></TextStyle>     
                </div>
            </div>
        )

    }
}

export default IndexValues
