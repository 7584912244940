import React, { Component } from "react"
import "./IndexWhat.css"
import TextStyle from "../style-compoment/TextStyle"

class IndexWhat extends Component {

    render () {
        
        return (
            <div className="indexWhat-container">
                <TextStyle
                    id="i-21"
                    class="big title animate"
                    french={["C'est quoi ", <span className="orange">Kodness ?</span>]}
                    english="Shipping within 3 days"
                    spanish="Envío en 3 días"
                    cssStyle={{color: "white"}}
                ></TextStyle>
                <TextStyle
                    id="i-22"
                    class="medium center indexWhat-text animate"
                    french={["Des entrainements accessibles et complets de renforcement musculaire,", <br className="indexWhat-br"></br>," de cardio training et de force pour vous et votre entourage !"]}
                    english={["FITNESS FOR ALL, ANYWHERE,", <br></br>,"WITHOUT CONSTRAINTS"]}
                    spanish={["ENTRENAMIENTO PARA TODOS", <br></br>,"EN CUALQUIER LUGAR, SIN LIMITACIONES"]}
                    cssStyle={{color: "white"}}
                ></TextStyle>
            </div>
        )

    }
}

export default IndexWhat