import React, { Component } from "react"
import "./IndexHome.css"
import "../../myStyle.css"
import TextStyle from "../style-compoment/TextStyle"
import { Link } from "gatsby"
import CallToAction from "../style-compoment/CallToAction"
import mainImage from "../../images/index-images/mainImage.jpg"

class IndexHome extends Component {

    sendToSubscribe = () => {

        const pos = document.getElementById('subscribe').offsetTop
        window.scrollTo(0, pos-100)    
    
    }


    render () {

        return (



            <div className="IndexHome-container">
                <div className="IndexHome-slogan-container">
                    <div className="IndexHome-second">
                        <TextStyle
                            id="i-11"
                            class="bigMainTitle title animate"
                            french={["Réinventer", <br></br>, "l'expérience", <br></br>, "sportive", <span className="orange"> à domicile.</span>]}
                            english={["FITNESS FOR ALL, ANYWHERE,", <br></br>,"WITHOUT CONSTRAINTS"]}
                            spanish={["ENTRENAMIENTO PARA TODOS", <br></br>,"EN CUALQUIER LUGAR, SIN LIMITACIONES"]}
                        ></TextStyle>
                        <TextStyle
                            id="i-12"
                            class="medium IndexHome-text animate"
                            french="Kodness, la station de musculation qui prend moins de place qu'un tabouret !"
                            english={["FITNESS FOR ALL, ANYWHERE,", <br></br>,"WITHOUT CONSTRAINTS"]}
                            spanish={["ENTRENAMIENTO PARA TODOS", <br></br>,"EN CUALQUIER LUGAR, SIN LIMITACIONES"]}
                        ></TextStyle>
                        <div onClick={() => this.sendToSubscribe()}>
                            <CallToAction
                                french="Devenez bêta testeur"
                                english="Shop"
                                spanish="Tienda"
                                color="navy"
                            ></CallToAction>
                        </div>
                    </div>
                </div>

                <img
                    className="IndexHome-image"
                    src={mainImage}
                    alt="Exerices presentation" 
                ></img>

            </div>
            

        )

    }
}

export default IndexHome