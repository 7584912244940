import React, { Component } from "react"
import "../../myStyle.css"
import "./IndexPresentation.css"
import TextStyle from "../style-compoment/TextStyle"
import { StaticImage } from "gatsby-plugin-image"
import level from "../../images/index-images/level.gif"

class IndexPresentation extends Component {



    render () {
      
        return (    
            <div className="IndexPresentation-container">
               
               <div className="IndexPresentation-second">
                    <StaticImage
                        className="IndexPresentation-image"
                        src="../../images/index-images/compact.jpg"
                        alt="stool"
                    />
                    <TextStyle
                        id="i-31"
                        class="medium IndexPresentation-text animate"
                        french={["Une station de musculation", <span className="bold"> ultra compacte de 52 x 35 x 52 cm</span>, " idéale pour vos entraînements à domicile."]}
                        english="Shipping within 3 days"
                        spanish="Envío en 3 días"
                    ></TextStyle>
               </div>

               <div className="IndexPresentation-second IndexPresentation-reverse">
                    <TextStyle
                        id="i-32"
                        class="medium IndexPresentation-text animate"
                        french={["Plus de", <span className="bold"> 50 exercices</span>, " pour entraîner l’ensemble de votre corps.", <br></br>, <br></br>,"Utilisable intégralement ", <span className="bold"> sans point d’accroche</span>, " (porte, poteau, accroche murale, etc.).", <br></br>, <br></br>, <span className="bold">Gagnez du temps </span>, "en enchainant vos exercices très rapidement."]}
                        english="Shipping within 3 days"
                        spanish="Envío en 3 días"
                    ></TextStyle>
                    <StaticImage
                        className="IndexPresentation-image"
                        src="../../images/index-images/polyvalent.jpg"
                        alt="stool" 
                    />
               </div>

               <div className="IndexPresentation-second">
                    <img className="IndexPresentation-image" src={level} alt="stool"></img>
                    <TextStyle
                        id="i-33"
                        class="medium IndexPresentation-text animate"
                        french={[<span className="bold">14 niveaux de difficulté</span>, " allant ", <span className="bold">de 3 à 45 kg ajustables en quelques secondes</span>," pour progresser à votre rythme et gagner du temps durant vos entraînements."]}
                        english="Shipping within 3 days"
                        spanish="Envío en 3 días"
                    ></TextStyle>
               </div>

               <div className="IndexPresentation-second IndexPresentation-reverse">
                    <TextStyle
                        id="i-34"
                        class="medium IndexPresentation-text animate"
                        french={[<span className="bold">Entrainez votre cardio et vos abdos</span>, " avec notre rail de rameur ", <span className="bold">ultra compact </span>, "connectable au Kodness.", <br></br>, <br></br>, "Un appareil efficace pour développer vos abdominaux et améliorer votre cardio. C'est l'outil idéal pour ", , <span className="bold"> compléter vos entrainements de renforcement musculaire.</span>]}
                        english="Shipping within 3 days"
                        spanish="Envío en 3 días"
                    ></TextStyle>
                    <StaticImage
                        className="IndexPresentation-image"
                        src="../../images/index-images/rail.jpg"
                        alt="stool" 
                    />
               </div>

    
              
            </div>
        )

    }
}

export default IndexPresentation

