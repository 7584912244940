import React, { Component } from "react"
import "../../myStyle.css"
import "./IndexReview.css"
import right from "../../images/general-icon/right.png"
import left from "../../images/general-icon/left.png"
import apostrophe from "../../images/general-icon/apostrophe.png"
import TextStyle from "../style-compoment/TextStyle"
import "../style-compoment/TextStyle.css"

class IndexReview extends Component {

    state = {
        position: 1,
        screenWidth: 340
    }


    componentDidMount() {

        let itemWidth = 340

        this.setState({
            screenWidth: window.screen.width
        })

        if (window.screen.width < 500) {
           itemWidth = window.screen.width
        }
        
        const pos = this.state.position*itemWidth
        const indexReview = document.getElementById("id-index-review-second")

        indexReview.style.scrollBehavior = "auto"
        indexReview.scrollTo(pos, 0)
        indexReview.style.scrollBehavior = "smooth"
    }


    carousel = (direction) => {

        let position = this.state.position
        const indexReview = document.getElementById("id-index-review-second")
        const windowWidth = indexReview.offsetWidth

        let itemWidth = 340
        if (window.screen.width < 500) {
           itemWidth = this.state.screenWidth
        }

        const numberItem = parseInt(windowWidth/itemWidth)
        const items = 4

        if (direction === "left") {
            if (position > 1) {
                position -= 1
            } else {
                position = items

                indexReview.style.scrollBehavior = "auto"
                const pos = itemWidth*(position+1)
                indexReview.scrollTo(pos, 0)
                indexReview.style.scrollBehavior = "smooth"
            }
        }

        if (direction === "right") {
            if (position < reviewArr.length-numberItem) {
                position += 1
            } else {
                position = items + 1 - numberItem

                indexReview.style.scrollBehavior = "auto"
                const pos = itemWidth*(position-1)
                indexReview.scrollTo(pos, 0)
                indexReview.style.scrollBehavior = "smooth"

            }
        }
        
        this.setState({
            position: position
        })
        
        const newPosition = itemWidth*position
        
        
        document.getElementById("id-index-review-second").scrollTo(newPosition, 0)
        
     }


    render () {

        let reviews = []

        let w = 340 * reviewArr.length

        if (this.state.screenWidth < 500) {
            w = this.state.screenWidth * reviewArr.length
        }

        reviewArr.forEach((review, index) => {
            reviews.push(
                <div className="indexReview-comment-container">
                    <div className="indexReview-comment-second" key={"index-comment" + index}>
                        <img className="indexReview-apostrophe" src={apostrophe} alt="apostrophe"></img>
                        <p className="indexReview-comment-text">{review[1]}</p>
                        <p className="indexReview-comment-name">{review[2]}</p>
                        <p className="indexReview-comment-date">{review[3]}</p>
                    </div>
                </div>
            )
        })

     
        return (
            <div className="indexReview-container">
                <TextStyle
                    id="i-71"
                    class="title big center indexReview-title animate"
                    french={["Ce que les", <span className="orange"> utilisateurs</span>, " disent de nous"]} 
                    english="What customers say about us" 
                    spanish="Lo que dicen los clientes sobre nosotros"
                ></TextStyle>
                <div id="id-index-review-second" className="indexReview-second">
                    <div className="indexReview-three" style={{width: w}}>
                        {reviews}
                    </div>
                </div>
                <div className="indexReview-arrow-container">
                    <img onClick={() => this.carousel('left')} className="indexReview-arrow" src={left} alt=""></img>
                    <img onClick={() => this.carousel('right')} className="indexReview-arrow" src={right} alt=""></img>
                </div>
            </div>
        )

    }
}

export default IndexReview



const one = ["Super application", "Un matériel de musculation compact et polyvalent qui permet de travailler le bas comme le haut depuis chez moi. Très content d avoir pu m'entraîner avec cet appareil !", "Mathias", "20/09/2022"]
const two = ["Résistance fluide", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Kévin", "28/09/2022"]
const three = ["Polyvalent", "Les exercices s'enchainent très facilement et on change la résistance facilement, en très peu de temps", "Marie", "10/10/2022"]
const four = ["TRES COMPACT", "Un home gym facile à utiliser, il répond à mon besoin", "Mathieu", "11/10/2022"]
// const five = ["Bonne application", "N'ayant pas de point d'accroche solide pour mettre des élastiques", "Anonyme", "20/30/2020"]
// const six = ["Résistance super fluide", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Anonyme", "20/30/2020"]
// const seven = ["Super application", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Anonyme", "20/30/2020"]
// const eight = ["Pleins de niveaux de difficulté", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Anonyme", "20/30/2020"]
// const nine = ["Large résistance", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Anonyme", "20/30/2020"]
// const ten = ["Polyvalent", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Anonyme", "20/30/2020"]
// const eleven = ["Compact", "Un appareil complet permettant de s'entrainer intégralement à la maison. Pratique et compact, il me permet de progresser. De plus, l'application propose des programmes complets.", "Anonyme", "20/30/2020"]

const reviewArr = [
    one,
    two,
    three,
    four,
    // five,
    // six,
    // seven,
    // eight,
    // nine,
    // ten,
    // eleven,
    one,
    two,
    three,
    four,
    // five,
    // six,
    // seven,
    // eight,
    // nine,
    // ten,
    // eleven
]