import React, { Component } from "react"

import Header from "../components/basics-compoment/Header"
import Footer from "../components/basics-compoment/Footer"

import IndexHome from "../components/index-compoment/IndexHome"
import IndexSubscribe from "../components/index-compoment/IndexSubscribe"
import IndexVideo from "../components/index-compoment/IndexVideo"
import IndexDelivery from "../components/index-compoment/IndexDelivery"
import IndexReview from "../components/index-compoment/IndexReview"
import IndexApp from "../components/index-compoment/IndexApp"
import IndexPresentation from "../components/index-compoment/IndexPresentation"
import IndexWhat from "../components/index-compoment/IndexWhat"
import IndexValues from "../components/index-compoment/IndexValues"

import "../myStyle.css"
import HelmetCompoment from "../components/basics-compoment/HelmetCompoment"


class IndexPage extends Component {

    state = {
      w: 100
    }

    componentDidMount() {
      window.addEventListener('scroll', this.handleScrollIndex, true);

      if (window.screen.width < 500) {
        this.setState({
          w: 50
        })
      }

    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScrollIndex);
    }
      
    
    handleScrollIndex = () => {
      let lastScrollY = window.scrollY;
      let windowSize = window.innerHeight

      const arrayID = ["i-11", "i-12", "i-21", "i-22", "i-31", "i-32", "i-33", "i-34", "i-41", "i-42", "i-51", "i-61", "i-62", "i-63", "i-64", "i-71", "i-81", "i-82", "subscribe"]


        if (document.getElementById(arrayID[0]) !== null) {

          for(let i = 0; i < arrayID.length; i++) {

              const top = document.getElementById(arrayID[i]).offsetTop

              if ((top < (lastScrollY+windowSize-this.state.w)) &&  (top > lastScrollY-windowSize)) {

                  const currentElement = document.getElementById(arrayID[i])
                  currentElement.style.animationDuration = "800ms"
                  currentElement.style.animationName = "titleAnimation"
                  currentElement.style.opacity = "1"
                  currentElement.style.transform = "translate(0%, 0%)"

            }
          }

        }

    }

  
  render () {

    return (
      <React.Fragment>
        
        {/* <HelmetCompoment></HelmetCompoment>
        <Header page="home"></Header>
        <IndexHome></IndexHome>
        <IndexWhat></IndexWhat>
        <IndexPresentation></IndexPresentation> 
        <IndexApp></IndexApp>
        <IndexVideo></IndexVideo>
        <IndexDelivery></IndexDelivery>
        <IndexReview></IndexReview>
        <IndexValues></IndexValues>
        <IndexSubscribe></IndexSubscribe>
        <Footer></Footer>  */}
      </React.Fragment>
)

}

}

export default IndexPage

