import React, { Component } from "react"
import "./IndexVideo.css"
import TextStyle from "../style-compoment/TextStyle"
import CallToAction from "../style-compoment/CallToAction"
import { Link } from 'gatsby'
import videoKodness from '../../images/index-images/kodnessPresentation.mp4'

class IndexVideo extends Component {

    render () {
        
        return (
            <div className="indexVideo-container">
                <TextStyle
                    id="i-51"
                    class="big title indexVideo-title animate"
                    french={["Découvrez ", <br className="indexVideo-br"></br>,"l'expérience ", <span style={{color: "var(--orange)"}}>Kodness</span>]}
                    english="Shipping within 3 days"
                    spanish="Envío en 3 días"
                ></TextStyle>
                

                {/* <video className="indexVideo-product" controls >
                <source src={videoKodness} type="video/mp4"/>
                </video> */}
                {/* <video width="750" height="500" controls >
                <source src={videoKodness} type="video/mp4"/>
                </video> */}


{/* <iframe width="560" height="315" src="https://www.youtube.com/embed/-ufHIDO2nAw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                <iframe id="youtube" className="indexVideo-product" width="560" height="315" src="https://www.youtube.com/embed/-ufHIDO2nAw?loop=1&autoplay=1&mute=1&playlist=-ufHIDO2nAw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div style={{marginTop: "50px"}}>
                    <Link className="link" to='/homegym'>
                        <CallToAction
                            french="En savoir plus"
                            english="Learn more"
                            spanish="Más información"
                            color="navy"
                        ></CallToAction>
                    </Link>
                </div>
            </div>
        )

    }
}

export default IndexVideo
